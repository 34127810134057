import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { Section } from '../components/Section';
import Container from '../components/Container';
import PostList from '../components/PostList';

const Tag = (props) => {
  const { data, pageContext } = props;
  const { edges: posts } = data.allWordpressPost;
  const { title: siteTitle } = data.site.siteMetadata;
  const { name: tag, description } = pageContext;

  return (
    <Layout>
      <Helmet title={`${tag} | ${siteTitle}`} />
      <Section>
        <Container>
          <h1>{tag}</h1>
          <p>{description}</p>
          <PostList posts={posts} />
        </Container>
      </Section>
    </Layout>
  );
};

export default Tag;

export const pageQuery = graphql`
  query TagPage($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allWordpressPost(filter: { tags: { elemMatch: { slug: { eq: $slug } } } }) {
      totalCount
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`;
